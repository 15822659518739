




















































import LazyHydrate from 'vue-lazy-hydration';
import Cookies from 'cookie-universal';
import {
  useRoute,
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import { SfButton, SfModal, SfLink } from '@storefront-ui/vue';
import { useUiState } from '~/composables';
import useCart from '~/modules/checkout/composables/useCart';
import AppHeader from '~/components/AppHeader.vue';
import AppHeaderCheckout from '~/components/AppHeaderCheckout.vue';
import BottomNavigation from '~/components/BottomNavigation.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import TopBar from '~/components/TopBar/TopBar.vue';

export default defineComponent({
  name: 'DefaultLayout',
  head: {
    meta: [
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  },
  components: {
    SfButton,
    SfModal,
    SfLink,
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    AppHeaderCheckout,
    BottomNavigation,
    IconSprite,
    TopBar,
    AppFooter: () =>
      import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    LoginModal: () =>
      import(/* webpackPrefetch: true */ '~/components/LoginModal.vue'),
    Notification: () =>
      import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    const cookies = Cookies();
    const route = useRoute();
    const { isLoginModalOpen } = useUiState();
    const { load: loadCart } = useCart();
    const topbarDisabled = ref(false);
    const isCookiesAccepted = ref(false);
    isCookiesAccepted.value = cookies.get('vsf-accept-cookies');

    function checkPath() {
      topbarDisabled.value = !!(
        route.value.fullPath.includes('user-account') ||
        route.value.fullPath.includes('shipping') ||
        route.value.fullPath.includes('billing') ||
        route.value.fullPath.includes('payment')
      );
    }
    function closeCookies(isClose:boolean=false){
      isCookiesAccepted.value = isClose;
    }
    const switchHeader = () => {
      topbarDisabled.value = !topbarDisabled.value;
    };

    const dateNow = new Date();
    function add30DaysToDate(date, days) {
      var Date30 = new Date(date);
      Date30.setDate(Date30.getDate() + days);
      return Date30;
    }

    const acceptCookie = () => {
      cookies.set('vsf-accept-cookies', true, {
        expires: add30DaysToDate(dateNow, 30),
      });
      isCookiesAccepted.value = true;
    };

    watch(route, () => {
      checkPath();
    });

    onMounted(async () => {
      await loadCart();
      checkPath();
    });

    return {
      isLoginModalOpen,
      isCookiesAccepted,
      acceptCookie,
      route,
      checkPath,
      topbarDisabled,
      switchHeader,
      closeCookies
    };
  },
});
